@font-face {
  font-family: DirectiveFour;
  src: url("Directive_Four.otf") format("opentype");
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-logo {
  padding-top: 30px;
  height: 250px;
  pointer-events: none;
}

.twitter {
  padding-top: 10px;
  height: 6vmin;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  padding-top: 30px;
}

.headerText {
  color: black;
  margin: 20px;
  font-family: DirectiveFour;
}

.descriptionText {
  color: black;
  font-size: 36px;
  margin: 20px;
}

.footerText {
  color: black;
}
